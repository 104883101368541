@import url("./assets/fonts/fonts.css");

:root {
  /* Variables for colors definition */
  --white-color: #FFF;
  --black-color: #000;
  --background-color: #FFF;
  /*#E5E5E5;*/
  --background-info-color: rgba(11, 94, 170, 0.08);
  --background-message-color: rgba(245, 245, 245, 1);
  --background-alert-color: rgba(234, 248, 252, 1);
  --primary-outlined-resting-border: rgba(11, 94, 170, 0.5);

  --navbar-background-color: rgba(7, 2, 13, 1);
  --navbar-background-color-selected: rgba(58, 53, 62, 1);

  /*****react-tostify-override***********************/
  --toastify-color-info: rgb(232, 244, 253);
  --toastify-color-success: rgba(239, 247, 238, 1);
  --toastify-color-warning: rgb(255, 244, 229);
  --toastify-color-error: rgb(253, 236, 234);

  --toastify-toast-width: 400px;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;

  --toastify-text-color-info: rgb(13, 60, 97);
  --toastify-text-color-success: rgb(30, 70, 32);
  --toastify-text-color-warning: rgb(102, 60, 0);
  --toastify-text-color-error: rgb(97, 26, 21);
}

/**************************************************/

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);

  /*NoSelect*/
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*****hide-autofill********************************/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/**************************************************/

/*****hide-number-arrows***************************/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

/**************************************************/

/*****hide-blue-highlight**************************/
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/**************************************************/

/*****custom-scrollbar*****************************/
::-webkit-scrollbar {
  width: 20px;
  height: 18px;
}

.dark::-webkit-scrollbar {
  background-color: var(--background-dark);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 8px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* ::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* div { */
/* -ms-overflow-style: none; */
/* IE and Edge */
/* scrollbar-width: none; */
/* Firefox */
/* } */
/**************************************************/

/*****grecaptcha-override**************************/
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: -55px !important;
  opacity: 0.3;
}

.grecaptcha-badge:hover {
  left: 0px !important;
  opacity: 1;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

/**************************************************/

/*****react-html5-camera-photo-override************/
@keyframes disabledCamera {
  0% {
    visibility: hidden;
  }

  75% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

#container-circles {
  position: absolute !important;
  left: 50% !important;
  bottom: 75px !important;
  animation: disabledCamera 1.5s !important;

}

#inner-circle,
#outer-circle {
  border-radius: 50%;
}

#outer-circle {
  position: absolute;
  left: -37px !important;
  height: 64px !important;
  width: 64px !important;
  background-color: hsla(0, 0%, 100%, .4);
  z-index: 1;
  display: flex;
}

#inner-circle {
  left: 60% !important;
  top: 39px !important;
  height: 32px !important;
  width: 32px !important;
  background: #fff;
  margin: -22px 0 0 -22px !important;
  z-index: 2;
}

/**************************************************/

/*****react-google-maps/api-override***************/
/* Action Buttons
.gmnoprint { display:none }
.gm-style-cc { display:none }
.gmnoprint div { background:none !important; } 
*/

/* Google link */
a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

/* Bottom line */
.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

/**************************************************/

/*****react-tostify-override***********************/
@media only screen and (max-width: 480px) {
  .Toastify__toast-theme--colored {
    margin: 16px;
  }
}

.Toastify__close-button>svg {
  color: var(--black-color)
}

/**************************************************/