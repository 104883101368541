@font-face {
  font-family: 'Lato';
  src: url('./Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: 'Next Regular';
  src: url('./NEXT_ART_Regular.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}